document.addEventListener('DOMContentLoaded', function () {
	objectFitImages();
	setUA();
	smoothScroll();
	toggleGnav();
	hideFixBnr();
})
window.addEventListener('load', function () {
	AOS.init({
		once: true,
		duration: 1000,
	});
})
window.addEventListener('scroll', function () {
});


function setUA() {
	//ua判定
	var ua = UAParser()
	var HTML = document.getElementsByTagName('html')[0];
	var uaString = {
		browserName: ua.browser.name.toLowerCase().replace(' ', '-'),
		browserVersion: ua.browser.major,
		browserEngine: ua.engine.name.toLowerCase().replace(' ', '-'),
		osName: ua.os.name.toLowerCase().replace(' ', '-'),
		type: (typeof ua.device.type !== 'undefined') ? ua.device.type.toLowerCase().replace(' ', '-') : 'laptop'
	}
	HTML.dataset.browser = uaString.browserName
	HTML.dataset.browserversion = uaString.browserVersion
	HTML.dataset.browserengine = uaString.browserEngine
	HTML.dataset.os = uaString.osName
	HTML.dataset.type = uaString.type
}
function smoothScroll() {
	$('a[href^="#"]').click(function () {
		// スクロールの速度
		var speed = 500; // ミリ秒
		// アンカーの値取得
		var href = $(this).attr("href");
		// 移動先を取得
		var target = $(href == "#" || href == "" ? 'html' : href);
		// 移動先を数値で取得
		var position = target.offset().top;
		// スムーススクロール
		$('body,html').animate({ scrollTop: position }, speed, 'swing');
		return false;
	});
}
function toggleGnav() {
	$('.js-menu').click(function () {
		$(this).toggleClass('is-active')
		$('.js-gnav').toggleClass('is-active')
		return false;
	})
	$('.js-gnav a').click(function(){
		$('.js-menu').toggleClass('is-active')
		$('.js-gnav').toggleClass('is-active')
	})
}
function hideFixBnr() {
	$(window).scroll(function () {
		if ($(window).scrollTop() + $(window).height() > $('.l-ftr').offset().top) {
			$('.js-fixBnr').stop().fadeTo(200, 0, function () {
				$('.js-fixBnr').hide();
			});
		} else {
			$('.js-fixBnr').stop().fadeTo(300, 1);
		}
	}).scroll();
}
